<template>
	<div>
		<headerTop></headerTop>
		<headerMin></headerMin>
		<!-- 主体 -->
		<div class="goodsDetails_content wrap bgw">
			<div class="indexes"> <span @click="$router.push('/')">首页</span> > 商品详情</div>
			<!-- 商品 -->
			<div class="goods">
				<div class="fl imgs">
					<img class="bigImg" :src="goods_img" />
					<div class="list">
						<img class="up" @click="arrowChange('up')" src="@/assets/image/Commoditydetails_Left@2x.png" alt />
						<div class="imgBox">
							<img :class="{select: i == selectImg_id}" :key="i" v-for="(v,i) in goods.banners && goods.banners.slice(0, 5)"
							 :src="v" @click="changeImg(v, i)" />
						</div>
						<img @click="arrowChange('down', goods.banners)" class="down" src="@/assets/image/Commoditydetails_Left@2x.png"
						 alt />
					</div>
					<div class="follow">
						<div v-if="isFavorite">
							<img src="@/assets/image/Commoditydetails_heart_Selection@2x.png" alt />
							<span @click="deleteGood">取消收藏</span>
						</div>
						<div v-else>
							<img src="@/assets/image/Commoditydetails_heart_default@2x.png" alt />
							<span @click="followGood">收藏</span>
						</div>
					</div>
				</div>
				<div class="txt fr">
					<p class="one title">{{goods.goods_name}}</p>
					<div class="box">
						<div>
							<div>
								价
								<i></i>
								<i></i>
								<i></i>
								<i></i> 格
							</div>
							<div class="money">￥{{ goods.price }} <span v-if="goods.member_dis_price!=0">￥{{goods.member_dis_price}}</span></div>
						</div>
						<div>
							<div>
								优
								<i></i>
								<i></i>惠
								<i></i>
								<i></i>券
							</div>
							<div class="discount" v-for="(v, i) in couponList" :key="i">
							    <p>满{{ parseInt(v.limit) }}减{{ parseInt(v.money) }}</p>
								<span  @click="openShowCoupon(v)">领取</span>
							</div>
							<div class="discount" v-if="couponList.length == 0">
								<p>暂无优惠券</p>
							</div>
							
						</div>
						<div v-if="disCountList.length>0||gifList.length>0">
							<div>
								活
								<i></i>
								<i></i>
								<i></i>
								<i></i>
								<i></i>
								<i></i>动
							</div>
							<div class="activity">
								<p class="one" v-if="disCountList.length>0">
									<span class="man">满折</span> 该商品
									    <span v-for="(v, i) in disCountList" :key="i">购满{{v.man_num}}{{v.unit}}享{{v.discount}}折优惠
										<span v-if="i != disCountList.length - 1">, </span>
									</span>
									<!-- <span v-if="disCountList.length == 0">无满折活动</span> -->
								</p>
								<p class="one" v-if="gifList.length>0">
									<span class="man">买赠</span> 该商品<span v-for="(v, i) in gifList" :key="i">满{{v.man_num}}赠{{v.give_num}}
										<span v-if="i != gifList.length - 1">, </span>
									</span>
									<!-- <span v-if="gifList.length == 0">无满赠活动</span> -->
								</p>
							</div>
						</div>
					</div>
					<div class="msg">
						<span>批准字号</span>
						<span>{{goods.license_number}}</span>
					</div>
					<div class="msg">
						<span>生产厂家</span>
						<span>{{goods.factoryname}}</span>
					</div>
					<div class="msg">
						<span>店铺</span>
						<span @click="goShopAdders">{{goods.shop_name}} <i class="el-icon-location-outline" style="color:red;font-weight: 700;"></i></span>
					</div>
					<div class="num">
						<span>
							数
							<i></i>
							<i></i>
							<i></i>
							<i></i>
							<i></i>
							<i></i>
							<i></i>
							<i></i>
							<i></i>
							<i></i> 量
						</span>
						<div class="numbox">
							<span @click="cut()">-</span>
							<input v-model="num" type="number" @input=" num<1 ? num=1:''" maxlength="2" />
							<span @click="add()">+</span>
						</div>
					</div>
					<div class="submit" v-if="goods.etcSaleStatus">
						<button @click="cart_add()">加入购物车</button>
						<button @click="GoGoodsOrder()">立即购买</button>
						<p>温馨提示：图片为实物拍摄，若出现新旧包装更换，请以收到实物为准！</p>
					</div>
					<div class="submit" v-else>
						<button @click="show_upload=true">上传处方购药</button>
						<button @click="goDoctor">线上问诊开方</button>
						<p>温馨提示：图片为实物拍摄，若出现新旧包装更换，请以收到实物为准！</p>
					</div>
				</div>
			</div>
			<!-- tab 底部TAB -->
			<div class="tab">
				<div class="tabtitle">
					<div @click="tabTxt = 0" :class="{tabtitleActive:tabTxt == 0}">商品介绍</div>
					<div @click="tabTxt = 1" :class="{tabtitleActive:tabTxt == 1}">参数与规格</div>
					<div @click="tabTxt = 2" :class="{tabtitleActive:tabTxt == 2}">商品评价 ({{ commentSum.commentsCount }})</div>
				</div>

				<!-- 商品介绍 -->
				<div v-show="tabTxt == 0" class="introduce">
					<div v-html="goods.content"></div>
				</div>
				<!-- 参数与规格 -->
				<div v-show="tabTxt == 1" class="parameter">
					<div class="list">
						<div class="fl">产品名称</div>
						<div class="fr">{{goods.goods_name}}</div>
					</div>
					<!-- 					<div class="list">
						<div class="fl">产品别名</div>
						<div class="fr">{{goods.other_name}}</div>
					</div> -->
					<div class="list">
						<div class="fl">生产批号</div>
						<div class="fr">{{goods.pihao}}</div>
					</div>
					<div class="list">
						<div class="fl">生产日期</div>
						<div class="fr">{{goods.sc_riqi}}</div>
					</div>
					<div class="list">
						<div class="fl">有效期</div>
						<div class="fr">{{goods.youxiaoqi}}</div>
					</div>
					<div class="list">
						<div class="fl">产品编码</div>
						<div class="fr">{{goods.goods_code}}</div>
					</div>
					<div class="list">
						<div class="fl">助记码</div>
						<div class="fr">{{goods.wareabc}}</div>
					</div>
					<div class="list">
						<div class="fl">批准文号</div>
						<div class="fr">{{goods.license_number}}</div>
					</div>
					<div class="list">
						<div class="fl">包装规格/单位</div>
						<div class="fr">{{goods.spec}}/{{goods.unit}}</div>
					</div>
					<div class="list">
						<div class="fl">生产企业</div>
						<div class="fr">{{goods.factoryname}}</div>
					</div>
				</div>
				<!-- 评价 -->
				<div v-show="tabTxt == 2" class="assess">
					<div class="assessTab">
						<div @click="commentType()">全部评价（ {{commentSum.commentsCount}} ）</div>
						<div @click="commentType('img')">有图（ {{commentSum.commentsImgCount}} ）</div>
						<div @click="commentType(3)">好评（ {{commentSum.commentsGoodCount}} ）</div>
						<div @click="commentType(2)">中评（{{commentSum.commentsMiddleCount}} ）</div>
						<div @click="commentType(1)">差评（{{commentSum.commentsBadCount}} ）</div>
					</div>
					<div v-for="(v,i) in commentList" :key="i" class="assessMsg">
						<div class="fl">
							<img :src="v.avatar" />
							{{ v.user_nickname }}
						</div>
						<div class="fr">
							<p>{{ v.msg }}</p>
							<div class="imglist">
								<img :src="it" v-for="(it, index) in v.img" :key="index" />
							</div>
							<span>{{ v.time }}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="user_Top_Cart" @click="$router.push('./cart')">
			<div class="cart">
				<img src="@/assets/image/right_ShoppingCart_icon_white@2x.png" alt />
				<br />购物车
			</div>
		</div>
		<div class="upload_Prescription" v-if="show_upload">
			<div class="conent_box">
				<div class="title">
					注意：拍照上传处方单请保持光线充足，字体清晰以提高处方单通过的几率。
					<span class="el-icon-close" @click="show_upload=false"></span>
				</div>
				<div class="upload_content">
					<div class="drugs_box">
						<img :src="goods.goods_img">
						<div class="drugs_name">
							{{goods.goods_name}}
						</div>
						<div>
							x{{num}}
						</div>
					</div>
					<div class="upload_img">
						<div class="upload_btn" v-if="upload_imgurl==''">
							<p class="el-icon-camera icon"></p>
							<p>点击上传处方单图片</p>
						</div>
						<div class="upload_btn" v-else>
							<img :src="upload_imgurl">
						</div>
						<input ref="upload_img" @change="OpenUpload" type="file" id="test-image-file" name="test" accept="image/gif, image/jpeg, image/png, image/jpg">
					</div>
					<div class="bottom_btn">
						<button class="clear" @click="clearimg">清除图片</button>
						<button class="upload" @click="upload_file">确认上传</button>
					</div>
				</div>
			</div>
		</div>
		 <el-button type="text" @click="open"></el-button>
		<div class="bg1">
			<publicBottom></publicBottom>
		</div>
		<div @mousewheel.prevent  class="mask" v-if="showCoupon" >
			<!-- -->
			<div class="coupon_warp" @click.stop>
				<p class="title">领取成功</p>
				<div class="content">
					<p>
						获得{{ parseInt(currentCoupon.money) }}元优惠券
					</p>
					<p>
						满{{parseInt(currentCoupon.limit)}}元可用
					</p>
					<p>
						<span>使用时间</span>
						{{ currentCoupon.start_time}} -
						{{ currentCoupon.end_time}}
					</p>
				</div>
				 <el-button @click="showCoupon = false">关闭</el-button>
			</div>
		</div>
	</div>
</template>
<script>
	import headerTop from "@/components/public/public_headerTop.vue";
	import headerMin from "@/components/index/index_header_min";
	import publicBottom from "@/components/public/public_bottom";

	import {
		goods_info
	} from "@/request/index"; //商品详情
	import {
		add_cart
	} from "@/request/index"; //加入购物车
	import {
		Comment_goods
	} from "@/request/index"; // 评论
	import {
		getCommentCount
	} from "@/request/index"; // 评论数量

	import {
		setShoucangGood
	} from "@/request/index";
	import {
		getGoodsCoupon
	} from "@/request/index"; // 获取商品优惠券
	import {
		getActives
	} from "@/request/index"; // 获取商品活动内容
	import {
		getCoupons
	} from "@/request/user";


	export default {
		components: {
			headerTop,
			headerMin,
			publicBottom
		},
		data() {
			return {
				searchState: 0,
				tabTxt: 0,
				num: 1,
				goods: "",
				shop_id: 0,
				ids: 0,
				commentSum: [], // 评论数量
				commentList: [], //评论
				originCommentList: [],
				goods_img: "", // 商品主图
				selectImg_id: 0,
				isFavorite: false, // 商品是否被收藏
				couponList: [],
				currentCoupon: {},
				showCoupon: false,
				gifList: [], // 买赠列表
				disCountList: [],
				upload_imgurl: "",
				formData: "",
				show_upload:false,
			};
		},
		created() {
			let id = this.$route.query.id;
			let shop_id = this.$route.query.shopId;
			this.shop_id = shop_id;
			this.start(id, shop_id);
			window.scrollTo(0,0);
		},
		methods: {
			open() {
			        this.$alert('请前往个人中心>服务>我的处方查看审核结果', '上传成功', {
			          confirmButtonText: '确定',
			        });
			      },
			// 上传
			upload_file() {
				this.$axios.post("/api/file/upload", this.formData).then(res => {
					if (res.data.code == 1) {
						this.$axios.post("/api/Prescription/userAdd", {
							token: this.$token,
							shop_id: this.shop_id,
							goods_id: this.goods.id,
							pic: res.data.data.file.url,
						}).then(res => {
							if (res.data.code == 1) {
								this.open();
								this.show_upload=false;
							} else {
								this.$message.error()(res.data.msg);
							}
						})
					}
				});

			},
			// 清除
			clearimg() {
				// 清除图片
				console.log(111);
				this.upload_imgurl = "";
			},
			// 选择图片
			OpenUpload(e) {
				console.log(e);
				let files = e.target.files[0];
				this.formData = new FormData();
				this.formData.append("file", files);
				this.formData.append("token", this.$token);
				let url = "";
				let reader = new FileReader();
				reader.readAsDataURL(files)
				let that = this;
				reader.onload = function() {
					url = this.result.substring(this.result.indexOf(",") + 1);
					that.upload_imgurl = "data:image/png;base64," + url;
				}
			},
			// 跳转问诊
			goDoctor() {
				this.$router.push({
					name: "线上问诊"
				})
			},
			// 跳转定位页
			goShopAdders() {
				this.$router.push({
					name: "药店地址",
					query: {
						id: this.goods.shop_id
					}
				})
			},
			cut() {
				if (this.num > 1) {
					this.num--;
				}
			},
			add() {
				if (this.num < 98) {
					this.num++;
				}
			},
			// 加入购物车
			cart_add() {
				if (this.$user.state == 1) {
					add_cart({
						token: this.$user.token,
						goods_id: this.goods.id,
						shop_id: this.shop_id,
						num: this.num
					}).then(res => {
						if (res.code == 1) {
						if(res.msg=="库存不足"){
							this.$message.error(res.msg)
						}else{
							this.$message.success(res.msg)
						}
						}
					});
				} else {
					this.$message.error("请登录后购买");
				}
			},
			// 跳转确认订单页
			GoGoodsOrder() {
				if (this.$user.state == 1) {
					if (this.num > this.goods.stock_num) {
						this.$message.error("商品库存不足");
						return;
					}
					this.$router.push({
						name: "确认订单",
						query: {
							shop_id: this.shop_id,
							id: this.goods.id,
							num: this.num
						}
					});
				} else {
					this.$message.error("请登录后购买");
				}
			},
			// 获取商品相关内容
			start(id, shop_id) {
				// 商品信息
				goods_info({
					token: this.$token,
					id: id,
					shop_id: shop_id
				}).then(res => {
					this.goods = res.data;
					console.log(this.goods);
					this.goods_img = this.goods.goods_img;
					this.isFavorite = this.goods.isFavorite;
				});
				getCommentCount({
					id: id
				}).then(res => {
					console.log(res.data);
					this.commentSum = res.data
				});
				//  评论 信息
				Comment_goods({
					goods_id: id,
					shop_id: shop_id
				}).then(res => {
					this.commentList = res.data.data;
					this.originCommentList = this.commentList;
					this.originCommentList.reverse();
				});
				// 商品优惠券
				getGoodsCoupon({
					shop_id: shop_id,
					goods_id: id
				}).then(res => {
					if (res.code == 1) {
						this.couponList = res.data;
					}
				});

				// 获取商品活动内容
				getActives({
					shop_id: shop_id,
					goods_id: id
				}).then(res => {
					if (res.code == 1) {
						this.gifList = res.data.giftRules;
						this.disCountList = res.data.discRules;
						console.log(this.disCountList);
					}
				});
			},
			openShowCoupon(v) {
		
				getCoupons({
					token: this.$token,
					id: v.id
				}).then(res => {
					if (res.code == 1) {
						this.currentCoupon = v;
							this.showCoupon = true;
					} else {
						this.$message.error(res.msg);
					}
				});
			},
			commentType(type) {
				this.commentList = this.originCommentList;
				if (type == "img") {
					this.commentList = this.commentList.filter(item => item.img != "");
				} else if (type == 1) {
					this.commentList = this.commentList.filter(item => item.star == 1);
				} else if (type == 2) {
					this.commentList = this.commentList.filter(item => item.star == 2);
				} else if (type == 3) {
					this.commentList = this.commentList.filter(item => item.star == 3);
				}
			},
			changeImg(v, i) {
				this.selectImg_id = i;
				this.goods_img = v;
			},
			arrowChange(type, banners) {
				if (type == "up") {
					if (this.selectImg_id == 0) return;
					this.selectImg_id--;
					this.goods_img = this.goods.banners.slice(
						this.selectImg_id,
						this.selectImg_id + 1
					);
				} else if (type == "down") {
					if (this.selectImg_id == banners.length - 1) return;
					if (this.selectImg_id == 4) return;
					this.selectImg_id++;
					this.goods_img = this.goods.banners.slice(
						this.selectImg_id,
						this.selectImg_id + 1
					);
				}
			},
			followGood() {
				// 关注商品
				setShoucangGood({
					id: this.goods.id,
					shop_id: this.shop_id,
					token: this.$token
				}).then(res => {
					if (res.code == 1) {
						this.isFavorite = true;
						this.$message({
							type: "success",
							message: res.msg
						});
					}
				});
			},
			deleteGood() {
				// 取消收藏
				setShoucangGood({
					token: this.$token,
					shop_id: this.shop_id,
					id: this.goods.id
				}).then(res => {
					if (res.code == 1) {
						this.isFavorite = false;
						this.$message({
							type: "success",
							message: res.msg
						});
					}
				});
			}
		}
	};
</script>
<style lang="less" scoped>
	@import "../../assets/less/index";
	.mask{
		position: fixed;
		top: 50%;
		left:50% ;
		transform: translate(-50%,-50%);
		z-index: 99999;
		background-color: #fff;
		border:10px solid;
		padding: 10px;
		border-color: rgba(0,0,0,0.2);
		width: 400px;
		.coupon_warp{
			/deep/.el-button{
				float: right;
			}
			.content{
				p{
					margin: 10px 0;
				}
			}
			.title{
				font-size: 18px;
				margin-bottom: 20px;
			}
			.cloce{
				float: right;
				border:1px solid #ccc;
				padding: 5px 10px;
			}
		}
	}
	.discount{
		margin-right: 20px;
		span{
			border-bottom: 1px dashed #ee0000;
			color:#ee0000;
		}
	}
	.money{
		span{
			text-decoration:line-through;
			font-size: 26px;
			color:#555555;
		}
	}
	.upload_Prescription {
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, .5);
		position: fixed;
		top: 0;
		left: 0;
		z-index: 99999;

		.conent_box {
			width: 550px;
			height: 550px;
			background-color: #fff;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);

			.title {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 40px;
				background-color: #ffd9d9;
				color: #ee0000;
				font-size: 14px;
				text-align: center;
				line-height: 40px;

				span {
					width: 40px;
					height: 40px;
					// background-color: red;
					line-height: 40px;
					position: absolute;
					top: 50%;
					transform: translate(0, -50%);
					color: #000;
					font-size: 20px;

				}
			}

			.upload_content {
				margin-top: 60px;
				padding: 0 20px;

				.drugs_box {
					display: flex;

					img {
						width: 100px;
						height: 100px;
					}

					.drugs_name {
						flex: 5;
						margin-left: 20px;
						overflow: hidden;
						text-overflow: ellipsis;
					}
				}

				.upload_img {
					border: 1px solid #ccc;
					height: 200px;
					margin-top: 20px;
					position: relative;

					input {
						width: 150px;
						height: 150px;
						background-color: red;
						position: absolute;
						top: 25px;
						left: 50%;
						transform: translateX(-50%);
						opacity: 0;
					}

					.upload_btn {
						width: 150px;
						height: 150px;
						margin: 0 auto;
						background-color: #F0F0F0;
						margin-top: 25px;
						text-align: center;
						border-radius: 10px;
						cursor: pointer;

						img {
							width: 100%;
							height: 100%;
						}

						.icon {
							margin-top: 30px;
							font-size: 50px;
							color: #CCCCCC;
							margin-bottom: 10PX;
						}

						P {
							font-size: 14PX;
							color: #CCCCCC
						}
					}
				}

				.bottom_btn {
					display: flex;
					justify-content: space-around;

					button {
						width: 100px;
						height: 40px;
						margin-top: 20px;
						cursor: pointer;
					}

					.clear {
						background-color: #ccc;
					}
				}
			}
		}
	}

	.user_Top_Cart {
		position: fixed;
		bottom: 120px;
		height: 136px;
		width: 58px;
		right: 0;
		z-index: 9;
		cursor: pointer;

		.cart {
			width: 58px;
			text-align: center;
			height: 78px;
			background: #CCCCCC;
			padding-top: 14px;
			font-size: 13px;
			color: #ffffff;

			img {
				width: 30px;
				height: 27px;
				margin-bottom: 4px;
			}
		}

		.cart:hover {
			background-color: #4EEEC8;
		}

		.Top {
			width: 58px;
			height: 58px;
			text-align: center;
			padding-top: 14px;
			background: rgba(102, 102, 102, 1);

			img {
				width: 28px;
				height: 30px;
			}
		}
	}
</style>
